import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Switch, Route, Redirect } from 'react-router';
import { roles, routes } from '../../constants';
import { DealsTabType } from '../../types/deals/DealsTabType';
import { AppState } from '../../types/state/AppState';
import { Preloader } from '../common';
import { DealsState } from '../../types/state/DealsState';
import { DealsSelectionPanel } from './DealsSelectionPanel';
import { useCallback, useEffect, useState } from 'react';
import { dealsActions } from '../../actions/deals.actions';
import { head } from 'lodash';
import { Overview } from './tabs/overview/Overview';
import DealsContext from './DealsContext';
import { CompanyPopup } from './CompanyPopup/CompanyPopup';
import { DealDetail } from './tabs/dealDetails/DealDetails';
import { EditDealDetails } from './tabs/dealDetails/edit/EditDealDetails';
import { ClassDetails } from './tabs/class-detail/ClassDetails';
import { AmrInfo } from './tabs/amrInfo/AmrInfo';
import { AmrInfoDetails } from './tabs/amrInfo/AmrInfoDetails';
import Confirm from '../alerts/Confirm';
import { AllClasses } from './tabs/all-classes/AllClasses';
import { EditDocuments } from './tabs/documents/edit/EditDocuments';
import { Documents } from './tabs/documents/Documents';
import { user } from '../../user';
import { AmrInfoEdit } from './tabs/amrInfo/edit/AmrInfoEdit';
import { AmrClassDetails } from './tabs/amrInfo/AmrClassDetails';

export interface DealsRouteParams {
    dealReferenceName: string;
    classReferenceName: string;
    transactionReferenceName?: string;
    tab?: DealsTabType;
}

export const Deals = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { dealReferenceName, tab, classReferenceName } = useParams<DealsRouteParams>();
    const { data, isLoading, isDetailsLoading, selectedDeal } = useSelector<AppState, DealsState>(state => state.deals);

    const [companyForPopup, setCompanyForPopup] = useState<string | null>(null);
    const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] = useState(false);

    const isAdminOrDataEntry = user.hasRoles(roles.Administrator, roles.DataEntry);
    const withDocumentsAccess =  isAdminOrDataEntry || user.hasRoles(...roles.seller());

    const context = {
        companyForPopup,
        setCompanyForPopup,
        showDeleteConfirmationPopup,
        setShowDeleteConfirmationPopup,
    };

    const navigateToFirstDeal = useCallback(
        (tab?: DealsTabType) => {
            const firstDeal = head(data);

            if (firstDeal) {
                history.replace(routes.dealsUrl(firstDeal.referenceName, tab || DealsTabType.Overview));
            }
        },
        [history, data],
    );

    useEffect(() => {
        dispatch(dealsActions.getDeals());

        return () => {
            dispatch(dealsActions.reset());
        };
    }, [dispatch]);

    useEffect(() => {
        if (!tab && !dealReferenceName) {
            navigateToFirstDeal();
        }
    }, [dealReferenceName, data, classReferenceName, tab, navigateToFirstDeal]);

    useEffect(() => {
        if (dealReferenceName) {
            dispatch(dealsActions.getDealDetails(dealReferenceName));
        }
    }, [dealReferenceName, dispatch]);

    const handleDeleteDeal = () => {
        dispatch(dealsActions.deleteDealRequest(dealReferenceName));
        setShowDeleteConfirmationPopup(false);
    };

    const hasClosedTransactions = !!data?.find(d => d.referenceName === dealReferenceName)?.hasClosedTransactions;

    const renderRedirect = () => {
        if (!dealReferenceName || !data) {
            return null;
        }

        let tabToRedirect = tab || DealsTabType.Overview;

        if (!hasClosedTransactions || !withDocumentsAccess) {
            tabToRedirect = isAdminOrDataEntry ? DealsTabType.DealDetail : DealsTabType.Overview;
        }

        return <Redirect to={routes.dealsUrl(dealReferenceName, tabToRedirect)} />;
    };

    const renderRoutes = () => (
        <Switch>
            {(!isAdminOrDataEntry || hasClosedTransactions) && (
                <Route
                    key="overview"
                    exact
                    path={routes.dealsUrl(':dealReferenceName', DealsTabType.Overview, ':classReferenceName?')}
                    render={() => (
                        <Overview dealReferenceName={dealReferenceName} classReferenceName={classReferenceName} />
                    )}
                />
            )}
            {(isAdminOrDataEntry || hasClosedTransactions) && [
                <Route
                    key="dealDetail"
                    exact
                    path={routes.dealsUrl(':dealReferenceName', DealsTabType.DealDetail)}
                    render={() => <DealDetail />}
                />,
                <Route
                    key="amrInfo"
                    exact
                    path={routes.dealsUrl(':dealReferenceName', DealsTabType.AmrInfo)}
                    render={() => <AmrInfo />}
                />,
            ]}
            {isAdminOrDataEntry && (
                <Route
                    key="editDealDetail"
                    exact
                    path={routes.editDealUrl(':dealReferenceName', DealsTabType.DealDetail)}
                    render={() => <EditDealDetails />}
                />
            )}
            {hasClosedTransactions && [
                <Route
                    key="amrInfo"
                    exact
                    path={routes.dealAmrInfoEditUrl(':dealReferenceName', ':transactionReferenceName')}
                    render={() => <AmrInfoEdit />}
                />,
                <Route
                    key="amrClassDetails"
                    exact
                    path={routes.dealAmrInfoClassUrl(
                        ':dealReferenceName',
                        ':transactionReferenceName',
                        ':classReferenceName',
                    )}
                    render={() => <AmrClassDetails />}
                />,
                <Route
                    key="amrInfoDetailsWithTransaction"
                    exact
                    path={routes.dealAmrInfoUrl(':dealReferenceName', ':transactionReferenceName')}
                    render={() => <AmrInfoDetails />}
                />,
                <Route
                    key="amrInfoDetails"
                    exact
                    path={routes.dealAmrInfoUrl(':dealReferenceName')}
                    render={() => <AmrInfoDetails />}
                />,
                <Route
                    key="classDetails"
                    exact
                    path={routes.dealsUrl(':dealReferenceName', DealsTabType.ClassDetail, ':classReferenceName?')}
                    render={() => (
                        <ClassDetails dealReferenceName={dealReferenceName} classReferenceName={classReferenceName} />
                    )}
                />,
                <Route
                    key="allClasses"
                    path={routes.dealsUrl(':dealReferenceName', DealsTabType.AllClasses)}
                    render={() => <AllClasses />}
                />,
                ...(withDocumentsAccess
                    ? [
                        <Route
                            key="documents"
                            exact
                            path={routes.dealsUrl(':dealReferenceName', DealsTabType.Documents)}
                            render={() => <Documents />}
                        />,
                      ]
                    : []),
                ...(isAdminOrDataEntry
                    ? [
                        <Route
                            key="editDocuments"
                            exact
                            path={routes.editDealUrl(':dealReferenceName', DealsTabType.Documents)}
                            render={() => <EditDocuments />}
                        />,
                      ]
                    : []),
                <Route
                    key="amrInfo"
                    exact
                    path={routes.dealsUrl(':dealReferenceName', DealsTabType.AmrInfo)}
                    render={() => <AmrInfo />}
                />,
            ]}
            {renderRedirect()}
        </Switch>
    );

    return (
        <DealsContext.Provider value={context}>
            <div className="container container-deals">
                <div className="container-flex container-sidebar">
                    <DealsSelectionPanel
                        isLoading={isLoading}
                        data={data || []}
                        selectedReferenceName={dealReferenceName}
                    />
                    <div className="container-flex content-part-sidebar">
                        <Preloader inProgress={isDetailsLoading} fullScreen={true}>
                            {renderRoutes()}
                        </Preloader>
                    </div>
                </div>
            </div>

            {companyForPopup && (
                <CompanyPopup
                    onClose={() => setCompanyForPopup(null)}
                    companyReferenceName={companyForPopup}
                    collateralManagerReferenceName={selectedDeal?.collateralManager?.referenceName}
                />
            )}
            {showDeleteConfirmationPopup && (
                <Confirm
                    confirmButtonText="Yes"
                    onCancel={() => setShowDeleteConfirmationPopup(false)}
                    onConfirm={handleDeleteDeal}
                    title="Delete Confirmation"
                    text={`Are you sure you want to delete deal ${selectedDeal?.legalName}`}
                />
            )}
        </DealsContext.Provider>
    );
};
