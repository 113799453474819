import { useSelector } from 'react-redux';
import { DealsHeaderBase } from './DealsHeaderBase';
import { AppState } from '../../types/state/AppState';
import { DealsState } from '../../types/state/DealsState';
import { useParams } from 'react-router';
import { DealsRouteParams } from './Deals';
import IconSVG from '../../styles/svg-icons';
import { Link } from 'react-router-dom';
import { routes } from '../../constants';
import { DealIntexLink } from './DealIntexLink';

interface Props {
    editButtonUrl?: string;
    onDeleteClick?: () => void;
}

export const DealsDefaultHeader = ({ editButtonUrl, onDeleteClick }: Props) => {
    const { selectedDeal } = useSelector<AppState, DealsState>(state => state.deals);

    const { dealReferenceName } = useParams<DealsRouteParams>();

    if (!selectedDeal) {
        return null;
    }

    const renderRelatedTransIcon = () => (
        <Link
            target="_blank"
            to={`${routes.AMRPipeline}/?dealsReferenceNames=${dealReferenceName}&dealsLegalNames=${selectedDeal.legalName}`}
            className="btn-link"
        >
            <IconSVG name="related-transaction" width={16} height={16} />
            Related Trans
        </Link>
    );

    return (
        <DealsHeaderBase
            title={selectedDeal.legalName}
            dateUpdated={selectedDeal.dateUpdated}
            onDeleteClick={onDeleteClick}
            renderLinks={() => [
                renderRelatedTransIcon(),
                <DealIntexLink deal={selectedDeal} />,
            ]}
            editButtonUrl={editButtonUrl}
        />
    );
};
