import { useSelector } from 'react-redux';
import { DealsHeaderBase } from './DealsHeaderBase';
import { AppState } from '../../types/state/AppState';
import { DealsState } from '../../types/state/DealsState';
import { useHistory, useParams } from 'react-router';
import { DealsRouteParams } from './Deals';
import { ClassSelect } from './ClassSelect';
import { routes } from '../../constants';
import { ValitanaLink } from '../common/ValitanaLink';
import { BloombergLink } from '../common/BloombergLink';
import IconSVG from '../../styles/svg-icons';
import { Link } from 'react-router-dom';
import { OnHoverTooltip } from '../common';
import { DealIntexLink } from './DealIntexLink';

const RelatedTransactionIcon = () => (
    <>
        <IconSVG name="related-transaction" width={16} height={16} />
        Related Trans
    </>
);

interface Props {
    tab: string;
    editButtonUrl?: string;
}

export const DealsClassHeader = ({ tab, editButtonUrl }: Props) => {
    const history = useHistory();
    const { selectedDeal } = useSelector<AppState, DealsState>(state => state.deals);

    const { dealReferenceName, classReferenceName } = useParams<DealsRouteParams>();
    const activeClass = selectedDeal?.classes.find(c => c.referenceName === classReferenceName);
    const withTransactions = !!selectedDeal?.transactions?.length;

    if (!selectedDeal) {
        return null;
    }

    const renderRelatedTransIcon = () => {
        if (!withTransactions) {
            return (
                <OnHoverTooltip overlay="There are no related transactions">
                    <button className="btn-link" disabled>
                        <RelatedTransactionIcon />
                    </button>
                </OnHoverTooltip>
            );
        }

        return (
            <Link
                target="_blank"
                to={`${routes.AMRPipeline}/?dealsReferenceNames=${dealReferenceName}&dealsLegalNames=${selectedDeal.legalName}`}
                className="btn-link"
            >
                <RelatedTransactionIcon />
            </Link>
        );
    };

    const onChangeClass = (dealClassReferenceName: string) => {
        return history.push(routes.dealsUrl(dealReferenceName, tab, dealClassReferenceName));
    }

    return (
        <DealsHeaderBase
            title={selectedDeal.legalName}
            dateUpdated={selectedDeal.dateUpdated}
            editButtonUrl={editButtonUrl}
            renderTitleSuffix={
                selectedDeal.classes.length
                    ? () => (
                          <>
                              <div className="vertical-divider" />
                              <ClassSelect
                                  selectedClassReferenceName={classReferenceName}
                                  onChange={onChangeClass}
                                  classes={selectedDeal.classes}
                              />
                          </>
                      )
                    : undefined
            }
            renderLinks={() => [
                renderRelatedTransIcon(),
                <DealIntexLink deal={selectedDeal} />,
                <BloombergLink identifiers={activeClass} textLink />,
                <ValitanaLink identifiers={activeClass} textLink />,
            ]}
        />
    );
};
